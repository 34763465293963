import React from 'react'
import { graphql } from 'gatsby'
import SliceZone from '../components/slice-zone'
import Layout from '../components/layout'
import {Helmet} from "react-helmet";

const Page = ({ data }) => {
    if (!data) return null

    const page = data.prismicPage.data

    return (
        <Layout>
          <Helmet>
            <title>{page.title.text}</title>
            <meta name="description" content={page.description.text} />
          </Helmet>
          <SliceZone slices={page.body} />
        </Layout>
    )
}

export const query = graphql`
query PageQuery($uid: String) {
  prismicPage(uid: {eq: $uid}) {
    uid
    type
    id
    data {
      title {
        text
      }
      description {
        text
      }
      body {
        ... on PrismicPageBodyHeroImage {
          id
          slice_type
          slice_label
          primary {
            header {
              text
            }
            image {
              url
              alt
            }
          }
        }
        ... on PrismicPageBodyBenefitBlocks {
          id
          slice_type
          slice_label
          primary {
            benefit_one_icon {
              url
              alt
            }
            benefit_one_header {
              text
            }
            benefit_one_text {
              text
            }
            benefit_two_icon {
              url
              alt
            }
            benefit_two_header {
              text
            }
            benefit_two_text {
              text
            }
            benefit_three_icon {
              url
              alt
            }
            benefit_three_header {
              text
            }
            benefit_three_text {
              text
            }
            benefit_four_icon {
              url
              alt
            }
            benefit_four_header {
              text
            }
            benefit_four_text {
              text
            }
            benefit_five_icon {
              url
              alt
            }
            benefit_five_header {
              text
            }
            benefit_five_text {
              text
            }
          }
        }
        ... on PrismicPageBodyImagesTwo {
          id
          slice_type
          slice_label
          primary {
            image_left {
              url
              alt
            }
            image_right {
              url
              alt
            }
          }
        }
        ... on PrismicPageBodyFrequentlyAskedQuestions {
          id
          slice_type
          slice_label
          primary {
            header {
              text
            }
          }
          items {
            question {
              text
            }
            answer {
              text
            }
          }
        }
        ... on PrismicPageBodyContactForm {
          id
          slice_type
          slice_label
          primary {
            form_name {
              text
            }
          }
        }
        ... on PrismicPageBodySmallParagraph {
          id
          slice_type
          slice_label
          items {
            text {
              text
            }
          }
        }
        ... on PrismicPageBodySmallImage {
          id
          slice_type
          slice_label
          primary {
            image {
              url
              alt
            }
          }
        }
        ... on PrismicPageBodySpace {
          id
          slice_type
          slice_label
          primary {
            size
          }
        }
        ... on PrismicPageBodyEvents {
          id
          slice_type
          slice_label
          items {
            name {
              text
            }
            date {
              text
            }
            location {
              text
            }
            document_extension {
              text
            }
            document_link {
              url
            }
          }
        }
        ... on PrismicPageBodyFeaturedColumns {
          id
          slice_type
          slice_label
          primary {
            left_image {
              url
              alt
            }
            left_header {
              text
            }
            left_description {
              text
            }
            left_link_text {
              text
            }
            left_link {
              url
            }
            center_image {
              url
              alt
            }
            center_header {
              text
            }
            center_description {
              text
            }
            center_link_text {
              text
            }
            center_link {
              url
            }
            right_image {
              url
              alt
            }
            right_header {
              text
            }
            right_description {
              text
            }
            right_link_text {
              text
            }
            right_link {
              url
            }
          }
        }
        ... on PrismicPageBodyMembershipTable {
          id
          slice_type
          slice_label
          items {
            name {
              text
            }
            description {
              text
            }
            price {
              text
            }
            enroll_text {
              text
            }
            enroll_link {
              url
            }
          }
        }
      }
    }
  }
}
`

export default Page